import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  toggle(e) {
    e.stopPropagation();
    const input = this.element.querySelector("input[type='checkbox']");
    const changeEvent = new Event("change");

    this.element.classList.add("should-animate");
    input.checked = !input.checked;
    input.dispatchEvent(changeEvent);
  }
}