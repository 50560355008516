import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    setTimeout(() => {
      let content = this.element.textContent;

      // Match double-quoted strings
      const stringRegex = /"([^"]*)"/g;
      content = content.replace(stringRegex, (match) => `<span class='string'>${match}</span>`);

      // Match { } or [ ]
      const bracketRegex = /({[^}]*}|(\[[^\]]*\]))/g;
      content = content.replace(bracketRegex, (match) => {
        return `<div class='nested-data'><span class='bracket'>${match}</span></div>`;
      });

      // Set the modified content as HTML
      this.element.innerHTML = content;
    }, 100);
  }
}