// Allows dropdowns to be opened and dismissed
import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["dropdown", "dropdownBody", "dropdownMenu"];
  static values = {addFocusToParent: Boolean, graftMenu: Boolean, selector: String, graftMenuSelector: String};

  toggleDisplay() {
    this.dropdown.classList.contains("is-open") ? this.animateHide() : this.show();
    
    // CHecking for graft is needed since the dropdown is outside of the button now
    if (!this.graftMenuValue && !this.data.has("has-dismiss-click-listener")) {
      this.listenForDismissClick(this.dropdownMenu);
    }
  }

  toggleDropdown(e) {
    // Since grafted feature renders button outside of component, targets are not available
    if (this.graftMenuValue) {
      this.dropdown = document.querySelector(`${this.selectorValue}`);
      this.dropdownMenu = this.dropdown.querySelector(".dropdown-menu");
      this.dropdownBody = this.dropdown.querySelector(".dropdown-body");
      e.currentTarget.classList.toggle("is-open");
      this.graftMenu();
      this.toggleDisplay();
    } else {
      this.dropdown = this.dropdownTarget
      this.dropdownMenu = this.dropdownMenuTarget
      this.dropdownBody = this.dropdownBodyTarget
      this.toggleDisplay()
    }
  }

  show() {
    if (this?.addFocusToParentValue) this.dropdown.parentElement.classList.add("has-focus");
    this.dropdown.classList.add("is-open");
  }

  hide() {
    this.dropdown.classList.remove("is-open");
    this.cleanUpClasses();
  }

  graftMenu() {
    document.querySelector(`${this.graftMenuSelectorValue}`).appendChild(this.dropdown);
  }
  
  cleanUpClasses() {
    this.dropdownBody.classList.remove("animate-none", "vanish");
    this.dropdownMenu.classList.remove("animate-out");
    this.dropdown?.querySelector(".is-focused")?.classList?.remove("is-focused");
  }

  listenForDismissClick(menu) {
    this.data.set("has-dismiss-click-listener", true);
    document.addEventListener("click", (e) => {
      if (!this.dropdown.contains(e.target)) this.animateHide(menu);
    });
  }

  animateHide() {
    if (this?.addFocusToParentValue) this.dropdown.parentElement.classList.remove("has-focus");
    this.dropdownMenu.classList.add("animate-out");
    setTimeout(() => {
      this.hide();
    }, 500);
  }

  disconnect() {
    // if (this.dropdownBody) this.hide();
  }
}
