import { Controller } from '@hotwired/stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  // Application-wide lifecycle methods
  //
  // Use these methods to handle lifecycle callbacks for all controllers.
  // Using lifecycle methods is optional, so feel free to delete these if you don't need them.
  //
  // Arguments:
  //
  //   element - the element that triggered the reflex
  //             may be different than the Stimulus controller's this.element
  //
  //   reflex - the name of the reflex e.g. "Example#demo"
  //
  //   error/noop - the error message (for reflexError), otherwise null
  //
  //   id - a UUID4 or developer-provided unique identifier for each Reflex
  //

  addVanish(element) {
    element.classList.add("vanish");
  }

  removeVanish(element) {
    element.classList.remove("vanish");
  }

  toggleVanish(element) {
    element.classList.toggle("vanish");
  }

  focusFirstEl(parent = "main") {
    const firstFocusable = parent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0];
    firstFocusable.focus();
  }
  
  unfocusTarget(target) {
    setTimeout(() => {
      target?.blur();
    }, 500);
  }

  // Below - Custom toasts for design/toasts
  // When using this, avoid using connect() in the controller you're calling from and use initialize() instead, since there's a conflict with this connect.
  // If you have to use connect() in the controller, first call super.connect().
  renderToast(selector, message, iconType = "warning", reload_page = false, current_user_id = null, dismissTime = null, hideBtn = false) {
    if (this.alreadyHasToast(selector)) return;
    if (!selector.includes("#") && !selector.includes(".") && selector !== "body") selector = `#${selector}`; // Add prefix for # if passed without it or a custom class selector
    this.stimulate("ApplicationReflex#render_toast", selector, message, this.countToasts(), iconType, reload_page, current_user_id, dismissTime, hideBtn);  
  }
  
  alreadyHasToast(id) {
    const toast = document.getElementById(`toast-component-${id}`);
    return toast;
  }

  renderFlash(msg, name) {
    this.stimulate("ApplicationReflex#render_cable_flash", msg, name)
  }
  
  // Counts toasts on page as margin to component so they stack
  countToasts() {
    return document.querySelectorAll(".toast-component").length;
  }

  dismissToast(id) {
    if (!id.includes('toast-component')) id = `toast-component-${id}`; // Will fix ID if what's passed doesn't have the 'toast-component' prefix.

    const toast = document.getElementById(id);
    
    if (toast) {
      toast.classList.add('dismiss');
      setTimeout(() => {
        toast.remove();
      }, 550);
    }
  }

  beforeReflex (element, reflex, noop, id) {
    // document.body.classList.add('wait')
  }

  reflexQueued (element, reflex, noop, id) {
    // Reflex will be delivered to server upon reconnection
  }

  reflexDelivered (element, reflex, noop, id) {
    // Reflex has been delivered to the server
  }

  reflexSuccess (element, reflex, noop, id) {
    // show success message
  }

  reflexError (element, reflex, error, id) {
    this.renderFlash(
      "There was some sort of error. Please refresh (F5) the page.", "alert"
    );
  }

  reflexForbidden (element, reflex, noop, id) {
    // Reflex action did not have permission to run
    // window.location = '/'
  }

  reflexHalted (element, reflex, noop, id) {
    // handle aborted Reflex action
  }

  afterReflex (element, reflex, noop, id) {
    // document.body.classList.remove('wait')
  }

  finalizeReflex (element, reflex, noop, id) {
    // all operations have completed, animation etc is now safe
  }
}
