import ApplicationController from "./application_controller";
import {checkMultiselectsValidity} from "../../components/design/forms/multiselect/multiselect_component_controller";

export default class extends ApplicationController {
  //? Call this on a submit button to validate form before submitting
  validateMultiselectOnSubmit(e) {
    const formSelectorId = `#${e.currentTarget.form.id}`;
    let isValid;
    isValid = checkMultiselectsValidity(formSelectorId);
    if (!isValid) {
      e.preventDefault();
      return super.renderToast(
        "body", "Please address all the fields in red.", "warning", false, null, 4500, true
      );
    }
  }

}