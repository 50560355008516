import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  toggleRolesField() {
    document.querySelector("#no-roles-info")?.remove();
    fetch(`/roles_field?exp_app_id=${this.element.value}`)
      .then((r) => r.text())
      .then((html) => {
        document.querySelector("#agent-application-roles-row-container").outerHTML = html;
      });
  }
}