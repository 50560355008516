import ApplicationController from "../../../../../javascript/controllers/application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  connect() {
    new Cleave(this.element, {
      delimiter: '-',
      blocks: [3, 3, 4],
      numerical: true,
      numericOnly: true,
    });   
  }
}